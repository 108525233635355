import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";

@Injectable({ providedIn: 'root' })
export class PermissionsChecker {

	private readonly permissions: { [key: string]: string[] } = {
		'menu.dashboard': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales', 'sales.supervisor', 'logistics', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.patients': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.doctors': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales', 'sales.supervisor', 'logistics', 'logistics.supervisor', 'lab.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.exams': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics', 'logistics.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.reports': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.messages': ['sales.supervisor', 'logistics.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.receipts': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.invoices': ['sales.supervisor', 'logistics.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.inventory': ['sales.supervisor', 'logistics', 'logistics.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.merger': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'menu.admin': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'eopyy', 'eopyy.advanced', 'admin', 'accounting'],
		'exam.edit': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy.supervisor', 'admin', 'accounting.supervisor'],
		'report.invoices_by_period': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.receipts_by_period': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.exams_by_period': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.exams_by_period_iso': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.exams_templates_count_by_doctor': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.exams_by_doctor_insurance': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.referrals_count_by_doctor': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.exams_count_by_doctor': ['secretariat.advanced', 'secretariat.supervisor', 'sales', 'sales.supervisor', 'logistics.supervisor', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.referrals_eopyy': ['secretariat.advanced', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.user_activity': ['secretariat', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'lab.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.user_activity_summary': ['secretariat', 'secretariat.supervisor', 'sales.supervisor', 'logistics.supervisor', 'lab.supervisor', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.referrals_without_exams': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales', 'sales.supervisor', 'logistics', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.referrals_hidden': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales', 'sales.supervisor', 'logistics', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.referrals_by_doctor_insurance': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales', 'sales.supervisor', 'logistics', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.exams_without_invoices_by_doctor': ['admin', 'accounting', 'accounting.supervisor'],
		'report.inventory_by_doctor': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales', 'sales.supervisor', 'logistics', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.edited_insurance': ['eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
		'report.unknown_insurance': ['secretariat', 'secretariat.advanced', 'secretariat.supervisor', 'sales', 'sales.supervisor', 'logistics', 'logistics.supervisor', 'lab', 'lab.supervisor', 'eopyy', 'eopyy.advanced', 'eopyy.supervisor', 'admin', 'accounting', 'accounting.supervisor'],
	}

	constructor(private kcSvc: KeycloakService) {
	}

	checkPermission(key: string) {
		this.permissions;
		if (this.kcSvc.getUserRoles().includes('admin')) {
			return true;
		}
		if (key == 'menu.admin') {
			return false;
		}
		return true;
	}

	/*checkPermission(key: string) {
		const rolesAllowed = this.permissions[key] || [];

		for (let role of rolesAllowed) {
			if (this.kcSvc.isUserInRole(role)) {
				return true;
			}
		}
		return false;
	}*/
}
